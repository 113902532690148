<template>
  <div>
    <search-sim-card-carrier-tasks
      @submit="submitSearch"
    />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <retry-sim-card-carrier-tasks
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchSimCardCarrierTasks from '@/views/sim_card_carrier_tasks/Search'
import Pagination from '@/components/Pagination'
import { findSimCardCarrierTasks } from '@/api/sim_card_carrier_task'
export default {
  name: 'SimCardCarrierTasks',
  components: {
    SearchSimCardCarrierTasks,
    Pagination,
    RetrySimCardCarrierTasks: () => import('@/views/sim_card_carrier_tasks/Retry')
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      selectedRowKeys: [],
      submitting: false
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left'
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '运营商账户',
          dataIndex: 'carrier_account_name'
        },
        {
          title: '流水号',
          dataIndex: 'queue_id'
        },
        {
          title: '运营商流水号',
          dataIndex: 'group_transaction_id'
        },
        {
          title: '事件名称',
          dataIndex: 'execute_type'
        },
        {
          title: '事件内容',
          dataIndex: 'event_detail'
        },
        {
          title: '计划开始时间',
          dataIndex: 'execute_start_date'
        },
        {
          title: '实际开始时间',
          dataIndex: 'executed_at'
        },
        {
          title: '实际完成时间',
          dataIndex: 'finished_at'
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '失败原因',
          dataIndex: 'failure_reason'
        }
      ]
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findSimCardCarrierTasks(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
