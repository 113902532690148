import request from '@/utils/request'

// 获取运营商接口调用查询记录
export function findSimCardCarrierTasks(params) {
  return request({
    url: `/sim_card_carrier_tasks`,
    method: 'get',
    params
  })
}

// 重跑
export function retrySimCardCarrierTasks(data) {
  return request({
    url: `/sim_card_carrier_tasks/retry`,
    method: 'post',
    data
  })
}

// 运营商接口事件名称类别
export function findSimCardCarrierTaskExecuteTypeOptions() {
  return request({
    url: `/sim_card_carrier_tasks/execute_type/options`,
    method: 'get'
  })
}
